<template>
  <div class="box">
    <!-- <top-bar :title='this.$route.query.title' :left="true"></top-bar> -->
    <div class="workGoal">
      <div style="
          height: 40px;
          display: flex;
          align-items: center;
          background-color: white;
        ">
        <div style="
            height: 16px;
            width: 3px;
            background-color: #387df4;
            margin-left: 20px;
          "></div>
        <div style="
            font-size: 18px;
            color: black;
            font-weight: 600;
            margin-left: 5px;
          ">
          {{ this.$route.query.title }}任务添加
        </div>
      </div>
      <van-form @submit="onSubmit" input-align="right" error-message-align="right">
        <van-field required label="任务标题" type="text" style="border: 0" v-model="selectMsg.title" placeholder="请输入" :rules="[{ required: true, message: '请填写任务标题' }]" />
        <van-field label="目标类型" type="text" style="border: 0" v-model="title" placeholder="请输入" disabled />
        <van-field label="认领人" type="text" style="border: 0" v-model="selectMsg.getUser" placeholder="请输入" />
        <van-field required readonly label="完成状态" type="text" style="border: 0" v-model="selectMsg.finishStatus" placeholder="请选择" @click="finishStatusShow = true" :rules="[{ required: true, message: '请选择完成状态' }]" />
        <van-field readonly label="完成时间" type="text" style="border: 0" v-model="selectMsg.finishTime" placeholder="请选择" @click="timeShow = true" />
        <van-field v-model="selectMsg.workDesc" rows="1" autosize label="任务详情描述" type="textarea" placeholder="请输入留言" />
        <div style="display: flex; margin-top: 30px" class="uploadBox">
          <van-uploader v-model="imageList" :after-read="afterRead">
            <!-- <img style="width: 100px; height: 100px" src="@/assets/img/picture.png" alt="" /> -->
          </van-uploader>
          <!-- <van-uploader accept="video/*" v-model="videoList" :after-read="afterRead">
            <img style="width: 100px; height: 100px" src="@/assets/img/video.png" alt="" />
          </van-uploader> -->
        </div>
        <div  class="btns">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <!--  -->
    <van-popup v-model="finishStatusShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectDate.finishStatus" value-key="label" @confirm="statusConfirm" @cancel="finishStatusShow = false" />
    </van-popup>
    <van-popup v-model="timeShow" position="bottom" >
      <van-datetime-picker v-model="selectDate.finishTime" type="date" title="选择年月日" @confirm="timeConfirm" @cancel="timeShow = false" />
    </van-popup>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { formatterDate } from "@/utils/utils";
import { constants } from "zlib";
export default {
  components: {
    topBar,
  },
  data() {
    return {
      title: "",
      finishStatusShow: false,
      timeShow: false,
      imageList: [],
      videoList: [],
      selectMsg: {
        title: "",
        category: "",
        getUser: "",
        finishStatus: "",
        finishTime: "",
        workDesc: "",
        newFileMessages: [],
      },
      selectDate: {
        finishStatus: [
          { label: "完成", value: "0" },
          { label: "未完成", value: "1" },
        ],
      },
    };
  },
  methods: {
    statusConfirm(value) {
      this.selectMsg.finishStatus = value.label;
      this.finishStatusShow = false;
    },
    timeConfirm(value) {
      let a = formatterDate(value);
      this.selectMsg.finishTime = a;
      this.timeShow = false;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadFile(file);
    },
    uploadFile(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      formData.append("path", "files/jq/worktarget/temporary");
      this.$http({
        url: this.$http.adornUrl(`/wxapp/file/upload`),
        method: "post",
        data: formData,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.selectMsg.newFileMessages.push(data.fileMessage);
          this.$notify({ type: "success", message: "上传成功" });
        } else {
          this.$notify({ type: "danger", message: "上传失败" });
        }
      });
    },

    onSubmit() {
      let selectMsg = this.selectMsg;
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqworktarget/save"),
        method: "post",
        data: this.$http.adornData({
          title: selectMsg.title,
          category: selectMsg.category,
          getUser: selectMsg.getUser,
          finishStatus: selectMsg.finishStatus == "完成" ? 0 : 1,
          finishTime: selectMsg.finishTime,
          workDesc: selectMsg.workDesc,
          newFileMessages: selectMsg.newFileMessages,
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          // 加载状态结
          this.$router.go(-1);
          this.$notify({ type: "success", message: "提交成功" });
        } else {
          this.$notify({ type: "danger", message: "提交失败" });
        }
      });
    },
  },
  mounted() {
    this.selectMsg.category = this.$route.query.category;
    this.title = this.$route.query.title;
  },
};
</script>

<style scoped>
.notice {
  width: 100vw;
  height: 100vh;
  background-color: #f6f5f3;
  overflow: scroll;
}
.workGoal {
  width: 100vw;
  height: 100%;
  background-color: white;
  /* margin-top: 130px; */
}
</style>
